<template>
	<main>
		<HeaderTab :title="$t('global.titre_horse_archive')" :return_action="'navigationBack'"/>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<CustomTable
								ref="horse_archived_table"
								id_table="horse_archive"
								:busy.sync="table_busy"
								primaryKey="horse_id"
								:hrefsRoutes="config_table_hrefs"
								:hide_if_empty="true"
								:transformer="['HorseTransformer', 'withResidLieu']"
								:base-filters="filters"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
import Navigation from "@/mixins/Navigation.js"
import Horse from "@/mixins/Horse.js"

export default {
	name: "HorseArchivedList",
	mixins: [Navigation, Horse],
	data () {
		return {
			table_busy: true,
			config_table_hrefs: {
				'horse_nom': {
					routeUniqueName: 'horseFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse_id'
					}
				}
			},
			events_tab: {
				'TableAction::goToUnArchiveHorse': (horse_ids) => {
					this.unarchive(horse_ids)
				},
				'TableAction::DeleteHorses': this.deleteHorses
			},
		}
	},
	methods: {
		unarchive(horse_ids) {
			this.unArchiveHorses(horse_ids)
				.then(() => {
					this.$refs.horse_archived_table.refreshTable()
				})
		},
		async deleteHorses(horses) {
			const ids = horses.map(horse => horse.horse_id)
			
			this.devalideHorses(ids)
				.then(() => {
					this.$refs.horse_archived_table.refreshTable()
				})
		},
	},
	computed: {
		filters() {
			let filters = {
				tiers: {
					column: 'horse_inactive',
					operator: 'isEqualTo',
					value: 1
				}
			}

			return filters
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>
